<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <div class="col-md-12">
            <div class="card gutter-b">
              <div class="card-body">
                <div class="row">
                  <validation-provider
                    rules="required|clientNotEmpty"
                    name="Client company"
                    v-slot="{ valid, errors }"
                    class="col-md-3"
                  >
                    <b-form-group
                      id="input-group-3"
                      label="Client Company"
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-3"
                        v-model="clientSelected"
                        :options="clientOptions"
                        :state="errors[0] ? false : valid ? true : null"
                      ></b-form-select>

                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    id="input-group-3"
                    label="Upload Excel"
                    label-for="input-3"
                    class="col-md-3"
                  >
                    <b-form-file
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      @change="fileChosen"
                    ></b-form-file>
                    <b-form-text id="input-live-help"
                      >Download template
                      <a :href="downloadBatchUrl" target="_blank"
                        >here</a
                      ></b-form-text
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card gutter-b">
          <div class="card-body">
            <b-table
              responsive
              striped
              hover
              :items="previewData"
              :fields="fields"
            >
              <template #cell(progress)="data">
                <b-progress
                  :value="data.item.progress"
                  :variant="data.item.progress == 100 ? 'success' : 'danger'"
                ></b-progress>
              </template>
            </b-table>
          </div>
        </div>

        <submit-button :loading="loading" text="Submit" />
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { ref, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SubmitButton from "@/components/button/SubmitButton";

import { listClient, createOrder } from "@/api/job.api";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

class Trip {
  constructor(type, date) {
    this._id = Math.random()
      .toString(16)
      .slice(2);
    this.type = type;
    this.company = "";
    this.date = date;
    this.from_time = "00:00";
    this.to_time = "23:59";
    this.address = "";
    this.unit_no = "";
    this.contact_person = "";
    this.contact_number = "";
    this.remark = "";
  }
}

export default {
  name: "createbatchjob",
  components: {
    ValidationProvider,
    ValidationObserver,
    SubmitButton,
  },
  setup(_, context) {
    const file = ref();
    const previewData = ref([]);

    const clientOptions = ref([]);
    const clientSelected = ref();

    const fileChosen = (event) => {
      const excel = event.target.files ? event.target.files[0] : null;

      if (excel) {
        const reader = new FileReader();

        reader.onload = (e) => {
          var data = e.target.result;
          var workbook = XLSX.read(data, { type: "binary" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];

          const json = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: true,
            defval: "",
          });
          transformExcelToModel(json);
        };

        reader.readAsBinaryString(excel);
      }
    };

    const transformExcelToModel = (json) => {
      for (let i = 2; i < json.length; i++) {
        const referenceNo = json[i][0];

        const pickup = new Trip("PICKUP", json[i][2]);
        pickup.company = json[i][1];
        pickup.address = json[i][3];
        pickup.unit_no = json[i][4];
        pickup.contact_person = json[i][5];
        pickup.contact_number = json[i][6];
        pickup.remark = json[i][7];

        const delivery = new Trip("DELIVERY", json[i][9]);
        delivery.company = json[i][8];
        delivery.address = json[i][10];
        delivery.unit_no = json[i][11];
        delivery.contact_person = json[i][12];
        delivery.contact_number = json[i][13];
        delivery.remark = json[i][14];

        const jobFromExcel = {
          clientId: "",
          referenceNo: referenceNo,
          trips: [pickup, delivery],
          progress: "",
        };

        previewData.value.push(jobFromExcel);
      }
    };

    const onSubmit = async () => {
      loading.value = true;
      previewData.value.forEach(async (job) => {
        job.client = clientSelected.value;

        try {
          await createOrder(job);
          job.progress = 100;
        } catch (e) {
          job.progress = 25;
        }
      });

      loading.value = false;
    };

    onMounted(() => {
      context.root.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      listClient().then((response) => {
        const clients = response.data;

        clients.forEach((client) => {
          clientOptions.value.push({ text: client.name, value: client.id });
        });
      });

      setTimeout(() => {
        context.root.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    const loading = ref(false);

    return {
      file,
      onSubmit,
      clientOptions,
      clientSelected,
      fileChosen,

      loading,
      previewData,
      fields: [
        { key: "progress", label: "Progress" },
        { key: "referenceNo", label: "Reference No." },
        { key: "trips[0].company", label: "Pickup Company" },
        { key: "trips[0].date", label: "Pickup Date" },
        { key: "trips[0].address", label: "Pickup Address" },
        { key: "trips[0].unit_no", label: "Pickup Unit No." },
        { key: "trips[0].contact_person", label: "Pickup Contact Person" },
        { key: "trips[0].contact_number", label: "Pickup Contact Number" },
        { key: "trips[0].remark", label: "Pickup Remark" },
        { key: "trips[1].company", label: "Delivery Company" },
        { key: "trips[1].date", label: "Delivery Date" },
        { key: "trips[1].address", label: "Delivery Address" },
        { key: "trips[1].unit_no", label: "Delivery Unit No." },
        { key: "trips[1].contact_person", label: "Delivery Contact Person" },
        { key: "trips[1].contact_number", label: "Delivery Contact Number" },
        { key: "trips[1].remark", label: "Delivery Remark" },
      ],

      downloadBatchUrl: process.env.VUE_APP_BATCH_TEMPLATE_URL,
    };
  },
};
</script>
